<template>
    <a v-on:click="favAction()" href="javascript:void(0)" class="favourite" :class="store.businessDetail.business_info.is_favorite == 0 ? 'text-dark':''" ><!-- <fa :icon="['far','heart']" /> --><fa icon="heart" /> <span v-if="!hideText"> Favourite</span></a>
</template>
<script setup>
import { inject } from 'vue'
import { useBusinessStore } from '@/stores/business'
import { useToast } from "vue-toastification";
import { useStorage } from "vue3-storage";
import $ from 'jquery'
const storage = useStorage()
const store = useBusinessStore()
const swal = inject('$swal')
const toast = useToast();

const favAction = () => {
    if (storage.getStorageSync('token')) {
        let msg = ""
        if (store.businessDetail.business_info.is_favorite === undefined || store.businessDetail.business_info.is_favorite == 0) {
            msg = "You want to faviourite this."
        }else{
            msg = "You want to remove this in faviourites."
        }

        swal({
            title: 'Are you sure?',
            text: msg,
            showCancelButton: true,
            confirmButtonText: 'Yes, do it!',
            reverseButtons: true,
            customClass: {
                confirmButton: 'ond-btn-p',
                cancelButton: 'ond-btn-w me-3'
            },
            buttonsStyling: false
        }).then(async (result) => {
            if (result.isConfirmed) {
                const resp = await store.toggleFav(store.businessDetail.business_info.id,storage.getStorageSync('user').id)
                //console.log(resp)
                if (resp.status === 'success') {
                    store.businessDetail.business_info.is_favorite = !store.businessDetail.business_info.is_favorite
                    $('.favourite').toggleClass('text-dark')
                    toast.success('Status updated successfully!')
                }else{
                    toast.error(resp.message);
                }
            }
        })
    }else{
        $("#loginModal").modal('show')
    }
}
defineProps({ 
    hideText:{
        type: Boolean,
        default() {
            return false;
        }
    } 
});
</script>
