import { defineStore } from 'pinia'
export const useBookingStore = defineStore('booking', {
    state: () => ({ 
        request_:{
            request_date:null,
            description:'',
            employee:'',
            services:[],
            render_location:'',
            price:'',
            slug:''
        },
        search_:{
            services:[],
            deal:{},
            date:'',
            time_slot:'',
            professional:{},
            render_location:0,
            rendered_address:{
                complete_address:'',
                lat:'',
                lng:'',
                country:'',
                state:'',
                city:'',
                street:'',
                zip:''
            },
            rendered_address_apt:'',
            cart_date: new Date().toJSON().slice(0,10).replace(/-/g,'-'),
            // is_deal:false
            business:''
        },
        isLoading: false
    }),
    actions: {
        async sendRequestedQuote() {
            const resp = await this.axios.post('add_reuest_a_quote',this.request_).catch(error => {return error.response})
            return resp.data
        },
        async getBookingFilters(slug,params='') {
            let url = '';
            if (params.deal != '' && params.deal != null && params.deal != undefined) {
                url = 'bookingDeal/'+slug+'?deal='+params.deal
            }else{
                url = 'bookingServices/'+slug
                if(params.service != undefined && params.service != '' && params.service != null){
                    url += '?service='+params.service
                }
            }
            const resp = await this.axios.get(url).catch(error => {return error.response})
            return resp.data
        },
        async getTimeSlots(){
            const resp = await this.axios.post('get_time_slots',this.search_).catch(error => {return error.response})
            return resp.data
        }
    }
})