<template>
    <MainAppContainer>
        <main>
            <ProfileHeader :businessInfo="businessDetail" :favAction="toggleFav" />
            <!-- <NavBar :favAction="toggleFav" :businessInfo="businessDetail" /> -->
            <Booking :businessInfo="businessDetail" />
        </main>
    </MainAppContainer>
</template>

<script>
import MainAppContainer from '@/components/containers/MainAppContainer.vue'
import ProfileHeader from '@/components/businessProfile/ProfileHeader.vue'
// import NavBar from '@/components/businessProfile/NavBar.vue'
import Booking from '@/components/booking/Booking.vue'
import $ from 'jquery'
import axios from "axios";

export default {
    data() {
        return {
            businessDetail:{},
            id:''
        }
    },
    components: {
        MainAppContainer,
        ProfileHeader,
        // NavBar,
        Booking
    },
    created() {
        this.getBusinessDetail("forHeader")
    },
    methods: {
        getBusinessDetail(type){
            let thiss= this
            axios.get('business_detail/'+this.$route.params.slug,{params: { type: type }}).then(function (response) {
                if (response.data.status == 'success') {
                    thiss.businessDetail = response.data.data.business_info
                    thiss.businessData(response.data.data)
                }else if (response.data.status == 'not-found') {
                    //
                }
            })
            .catch(error => {
                if (error.response) {
                    console.log(error.response.data.message)
                }
            });
        },
        toggleFav(business_id){
            if (!this.$storage.getStorageSync('token')) {
                $("#loginModal").modal('show')
            }else{
                let thiss = this
                this.$swal({
                    title: 'Are you sure?',
                    text: "You want to change this!",
                    showCancelButton: true,
                    confirmButtonText: 'Yes, do it!',
                    reverseButtons: true,
                    customClass: {
                        confirmButton: 'ond-btn-p',
                        cancelButton: 'ond-btn-w me-3'
                    },
                    buttonsStyling: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        axios.post('do_favourite',{user_id: thiss.$storage.getStorageSync('user').id, business_id: business_id}).then(function (response) {
                            if (response.data.status == 'success') {
                                $('.favourite').toggleClass('text-dark')
                                thiss.toast.success('Status updated successfully!')
                            }
                        })
                        .catch(error => {
                            if (error.response) {
                                console.log(error.response.data.message)
                            }
                        });
                    }
                })
            }
        }
    }
}
</script>
