<template>
    <section class="booking-section">
        <div class="container">
            <div class="booking-wrapper">
                <div class="row" >
                    <div class="col-12 mb-4">
                        <h3 class="text-center">Make your selection</h3>
                    </div>
                    <div class="col-md-6 col-12">
                        <div class="form-group mt-2">
                            <label style="font-weight: 500" v-if="isDeal()">Deal</label>
                            <label style="font-weight: 500" v-else>Services List</label>
                            <Multiselect v-model="store.search_.services" :options="services" @select="updateService" @deselect="updateService" @clear="clearAll" :canDeselect="false" :mode="multipleSelectOptions.mode" :groupSelect="false" class="form-control mt-2" :placeholder='multipleSelectOptions.placeholder' :groups="true" :searchable="multipleSelectOptions.searchable" :hideSelected="false" :closeOnSelect="true">
                                <template v-slot:multiplelabel="{ values }">
                                    <div class="multiselect-multiple-label">
                                        <span v-for="(s,index) in values" :key="index">{{s.label}}<span v-if="values.length-1 != index ">, </span></span>
                                    </div>
                                </template>
                            </Multiselect>
                        </div>
                    </div>
                    <div class="col-md-6 col-12 pb-3">
                        <div class="form-group mt-2">
                            <label style="font-weight: 500">Where?</label>
                            <select class="form-select mt-2" v-model="store.search_.render_location" v-on:change="clearServices">
                                <option v-for="(loc,index) in render_locations" :key="index" :value="loc">
                                    <span v-if="loc == 1">At Business</span>
                                    <span v-if="loc == 2">My Location</span>
                                </option>
                                <!-- <option value="2">My Location</option> -->
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row" v-show="store.search_.render_location == 2">
                    <div class="form-group col-sm-6">
                        <input type="text" class="form-control" v-model="store.search_.rendered_address.complete_address" placeholder="Search address…" ref="origin" />
                    </div>
                    <div class="form-group col-sm-6">
                        <input type="text"
                            class="form-control"
                            placeholder="Apt/Unit/Suite (optional)"
                            v-model="store.search_.rendered_address_apt" />
                    </div>
                    <span style="color:#DC143C; display:none">Whoops! this location is out of our service area.</span>
                </div>
                <div class="row date-carousel">
                    <div class="col-12 mt-4">
                        <Carousel :items-to-show="5" :itemsToScroll="5" :breakpoints="breakpoints" id="bookingDateCarousel">
                            <Slide v-for="(st,index) in dates_Arr" :key="index">
                                <div class="booking-calendar-slider" :class="st.date == store.search_.date ? 'active':''">
                                    <div class="text-center booking-calendar-date" :class="[(st.status || store.search_.time_slot != '') ? 'cursor-not-allow':'cursor-pointer', st.status ? 'inactive' : '']"  v-on:click="getSlots('',st.date,st.status)">
                                        <div>{{st.day_title}}</div>
                                        <div>{{st.mon}}</div>
                                    </div>
                                </div>
                            </Slide>
                            <template #addons>
                                <Navigation />
                            </template>
                        </Carousel>
                    </div>
                </div>
                <div class="row mt-5 mb-4" v-if="store.search_.time_slot != ''">
                    <div class="col-12 text-center mb-3">
                        <h3 class="mb-1">Review</h3>
                        <h6>{{changeDateFormate(store.search_.date,'dddd, MMMM DD, YYYY')}} <br> {{store.search_.time_slot}}</h6>
                    </div>
                    <div class="col-12">
                        <div class="row" v-if="isDeal()">
                            <div class="col-md-4 mb-2" v-for="(serv,index) in store.search_.deal.deal_services" :key="index">
                                <div class="booking-vendor">
                                    <div class="booking-vendor-img" style="background: #0000 url('https://s3.us-east-2.amazonaws.com/images.ondaq.com/icons/user-icon.jpg') repeat scroll 50% 50% / cover padding-box border-box;"></div>
                                    <div id="DIV_10">
                                        <a href="javascript:void(0);" id="A_11">{{serv.service.title}} <span>({{serv.business_service.duration}} Min)</span></a>
                                        <div id="DIV_12">
                                            <span id="SPAN_14" ng-if="slot.service" style="font-size: 14px;">Price: <strong>${{serv.business_service.cost}}</strong></span>  <br>
                                            <!-- <span id="SPAN_14" >Appt: <strong>{{store.search_.time_slot}}</strong></span> <br> -->
                                            <span id="SPAN_14" class="text-muted" style="font-size: 14px;">By {{store.search_.professional.name}}</span><br>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-else>
                            <div class="col-md-4 mb-2" v-for="(serv,index) in store.search_.services" :key="index">
                                <div class="booking-vendor">
                                    <span class="remove_selection" v-on:click="removeService(index)"><fa :icon="['far','times-circle']" /></span>
                                    <div class="booking-vendor-img" style="background: #0000 url('https://s3.us-east-2.amazonaws.com/images.ondaq.com/icons/user-icon.jpg') repeat scroll 50% 50% / cover padding-box border-box;"></div>
                                    <div id="DIV_10">
                                        <a href="javascript:void(0);" id="A_11" class="fw-bold">{{serv.title}} <span>({{serv.duration}} Min)</span></a>
                                        <div id="DIV_12">
                                            <span id="SPAN_14" ng-if="slot.service">Price: <strong>${{serv.cost}}</strong></span>  <br>
                                            <span id="SPAN_14" >Appt: <strong>{{store.search_.time_slot}}</strong></span> <br>
                                            <span id="SPAN_14"  style="font-size: 14px;color: #222222;">By {{store.search_.professional.name}}</span><br>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="store.search_.time_slot == ''">
                    <div class="row mt-4" >
                        <div class="col-12 mb-2" v-if="isDeal()">
                            <span style="font-size: 1rem;font-weight: 600;">Services: </span>
                            <span v-for="(list, index) in store.search_.deal.deal_services" :key="index">
                                <span>{{list.service.title}}</span><span v-if="index+1 < store.search_.deal.deal_services.length">, </span>
                            </span>
                        </div>
                        <div class="col-12 mb-2" v-else>
                            <span style="font-size: 1rem;font-weight: 600;">Services: </span>
                            <!-- {{store.search_.deal}} -->
                            <span v-for="(list, index) in store.search_.services" :key="index">
                                <span>{{list.title}}</span><span v-if="index+1 < store.search_.services.length">, </span>
                            </span>
                        </div>
                        <div class="col-12" v-if="store.search_.date">
                            <h6>{{changeDateFormate(store.search_.date,'dddd, MMM DD, YYYY')}} ({{businessTimeZone}}) </h6>
                        </div>
                    </div>
                    <div class="row time-slot-sec" v-for="employee in timeSlots" :key="employee.id">
                        <div class="col-12 col-md-4 col-lg-3">
                            <div class="card mb-3 text-center">
                                <div class="card-body">
                                    <img v-if="employee.user.picture" :src="employee.user.picture" class="card-img-top" alt="Profile picture">
                                    <img v-else src="@/assets/images/icons/user-icon.jpg" class="card-img-top" alt="Profile picture">
                                    <h5 class="card-title">{{employee.user.name}}</h5>
                                    <p class="card-text mb-1">{{employee.profession.title}}</p>
                                    <p class="card-text"><small class="text-muted">{{employee.user.gender}}</small></p>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-8 col-lg-9">
                            <div class="time-block" v-for="(allSlots,index) in employee.available_slots" :key="allSlots.id">
                                <h5>{{index}}</h5>
                                <a v-for="(slot,i) in allSlots" :key="i" v-on:click.prevent="selectTimeSlot(slot,employee)" href="#" class="time-link-block">{{slot}}</a>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="timeSlots.length == 0 && store.search_.services.length > 0">
                        <div class="col-12 text-center">
                            <h5>Damn!</h5>
                            <p>Sorry there are no availabilities with any of our professional today.</p>
                            <button class="ond-btn-w fs-5 fw-bold" v-on:click="getSlots('',bookingNextAvailabilityDate)" v-if="bookingNextAvailabilityDate.length > 0" >Next Availability {{changeDateFormate(bookingNextAvailabilityDate,'dddd, MMM DD, YYYY')}}</button>
                        </div>
                    </div>
                </div>
                <div class="row" v-if="store.search_.time_slot != ''">
                    <div class="col-12 py-4">
                        <router-link :to="{name:'BusinessProfile',params:{slug:$route.params.slug}}" class="ond-btn-w float-start" >Cancel</router-link>
                        <button class="ond-btn-p float-end" v-on:click="goToCheckout">Continue &amp; Book</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
import { onMounted,ref,reactive } from 'vue'
import Multiselect from '@vueform/multiselect'
import { Carousel, Navigation, Slide } from 'vue3-carousel';
import moment from 'moment'
import 'vue3-carousel/dist/carousel.css';
import { useStorage } from "vue3-storage";

import { useBookingStore } from '@/stores/booking'
// import { useServicesStore } from '@/stores/services'
import { useRoute, useRouter } from 'vue-router'

const route = useRoute()
const router = useRouter()
const store = useBookingStore()
const storage = useStorage()

// const serviceStore = useServicesStore()
// console.log(route.query.service)

const services = ref([])
const render_locations = ref([])
const businessTimeZone = ref("")
const dates_Arr = ref([])
const timeSlots = ref([])
const bookingNextAvailabilityDate = ref('')
const origin = ref(null);
const autocomplete = ref(null);
store.search_.date = moment().format('YYYY-MM-DD')
store.search_.business = route.params.slug
const multipleSelectOptions = reactive({
    mode:'multiple',
    searchable:true,
    placeholder:'Search and choose a service'
})

const breakpoints = {
    500: {
        itemsToShow: 7,
        itemsToScroll: 7,
        // snapAlign: 'start',
        transition: '1000'
    },
    // 700px and up
    /*992: {
        itemsToShow: 4,
        itemsToScroll: 4,
        snapAlign: 'start',
    }*/
}
function isDeal(){
    if (route.query.deal != '' && route.query.deal != null && route.query.deal != undefined) {
        return true
    }
    return false
}

// function hasService(){
//     if(route.query.service != undefined && route.query.service != '' && route.query.service != null){
//         return true
//     }
//     return false
// }


const getFilters = async () => {
    const resp = await store.getBookingFilters(route.params.slug,route.query)
    // console.log(resp.data);
    if (resp.status === 'success') {
        services.value = resp.data.services
        render_locations.value = resp.data.renderLocations
        store.search_.render_location = resp.data.active_loc
        store.search_.services = resp.data.active_services
        businessTimeZone.value = resp.data.timeZone
    }
}
getFilters()

// Date SliderThree Month Dates Array
const today = moment();
// Get the start of the week (Monday)
const startOfWeek = today.clone().startOf('isoWeek');
for (var i = 0; i < 90; i++) {
    const currentDate = startOfWeek.clone().add(i, 'days');
    var d = currentDate.format('d');
    if (d == 0) {
        d = '7'
    }
    let disableStatus = false;
    if (currentDate.isBefore(today,'day')) {
        disableStatus = true;
    }
    //if (jQuery.inArray(parseInt(d), disableWeekDay) == -1 ) {
        dates_Arr.value.push({status:disableStatus,day:d,day_title:currentDate.format('ddd'),mon:currentDate.format('MMM D'),date:currentDate.format('YYYY-MM-DD')});
    //}
}
// console.log(dates_Arr.value);


// Add google places for rendere address
function getAddressArray (place) {
    var comp_address = {complete_address:'',lat:'',lng:'',street:'',city:'',state:'',country:'',zip:'',}
    comp_address.complete_address = place.formatted_address
    comp_address.lat = place.geometry.location.lat()
    comp_address.lng = place.geometry.location.lng()
    for (var i = 0; i < place.address_components.length; i++) {
        var addressType = place.address_components[i].types[0];
        if (addressType == "street_number") {
            comp_address.street += place.address_components[i]['short_name']
        }else if (addressType == "route") {
            comp_address.street += ' '+place.address_components[i]['long_name']+' '
        }else if (addressType == "locality") {
            comp_address.city = place.address_components[i]['long_name']
        }else if (addressType == "administrative_area_level_1") {
            comp_address.state = place.address_components[i]['short_name']
        }else if (addressType == "country") {
            comp_address.country = place.address_components[i]['long_name']
        }else if (addressType == "postal_code") {
            comp_address.zip = place.address_components[i]['short_name']
        }
    }
    return comp_address;
}
onMounted(() => {
    const input = origin.value;
    autocomplete.value = new window.google.maps.places.Autocomplete(input, { componentRestrictions: { country: ['US', 'CA'] } });
    autocomplete.value.addListener('place_changed', function(){
        const place = autocomplete.value.getPlace();
        store.search_.rendered_address = getAddressArray(place)
    });

    if (isDeal()) {
        multipleSelectOptions.mode = "single";
    }
});


const updateService = () => {
    if (store.search_.services.length == 0){
        clearAll()
    }else{
        timeSlotsProcess()
    }
}

const getSlots = (value='',date_val='',status=false) => {
    // console.log(status)

    if(status){
        return;
    }



    // console.log(date_val)
    if (value != '') {
        // if (isDeal()) {
        //     store.search_.deal = value
        // }else{
        //     store.search_.services = value
        // }
        // console.log(store.search_.services)
        return;
    }else if (value == '' && date_val == '') {
        store.search_.services = []
        timeSlots.value = []
        clearAll()
        return;
    }else if (date_val != '') {
        if (store.search_.time_slot == '') {
            store.search_.date = date_val
            if (isDeal()) {
                timeSlotsProcess()
            }else{
                if (store.search_.services.length > 0) {
                    timeSlotsProcess()
                }
            }
        }
    }
}
const timeSlotsProcess = async () =>{
    if (store.search_.time_slot == '' && store.search_.services.length > 0 && store.search_.date != undefined && store.search_.date != '' && store.search_.date != null) {
        // store.search_.business_id = this.businessInfo.id
        // let thiss = this
        // console.log(store.search_)
        timeSlots.value = [];
        const response = await store.getTimeSlots()
        // console.log(response.data);
        if (response.status === 'success') {
            timeSlots.value = response.data.data;
            bookingNextAvailabilityDate.value = response.data.nextAvailableDate;
        }
    }
}

const clearAll = () => {
    //this.servicesList()
    store.search_.professional = {}
    store.search_.time_slot = ''
    store.search_.services = []
    timeSlots.value = []
    updateServiceVisibility(0)
}

const updateServiceVisibility = (user_id) => {
    if (isDeal() == false) {
        services.value.forEach((cate) => {
            cate.options.forEach((serv) => {
                if (user_id != 0) {
                    if(serv.value.assigned_users.indexOf(user_id) == -1) {
                        serv.disabled = true
                    }else{
                        serv.disabled = false
                    }
                }else{
                    serv.disabled = false
                }
            });
        });
    }
}
const selectTimeSlot = (slot,data) => {
    console.log(data)
    let prof = {
        name:data.user.name,
        employee_id:data.id,
        user_id:data.user.id,
        image:data.user.picture
    }
    store.search_.professional = prof
    store.search_.time_slot = slot

    updateServiceVisibility(data.user.id)

    //this.servicesList(data.employee_id)
}
const changeDateFormate = (date,formate) => {
    return moment(date).format(formate)
}
const clearServices = () => {
    clearAll()
    getFilters()
}
const removeService = (index) => {
    store.search_.services.splice(index, 1);
    // console.log(store.search_.services.length)
    if (store.search_.services.length == 0){
        clearAll()
    }
}

const goToCheckout = () => {
    var data = {}
    data[route.params.slug] = store.search_
    storage.setStorageSync("cart",data)
    //console.log(store.search_)
    router.push({ name: 'Checkout', params: { slug: route.params.slug } })
}
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style>
    #bookingDateCarousel .carousel__slide{
        padding-bottom: 0px !important;
        padding-top: 0px !important;
        padding-right: 0px !important;
        padding-left: 0px !important;
    }
    .multiselect-search{
        position: relative;
    }
    .date-carousel{
        position: sticky;
        top: 44px;
        background: #fff;
        z-index: 1;
    }
    .multiselect-group-label.is-selected, .multiselect-group-label.is-selected.is-pointed{
        background: #e5e7eb;
        color: #374151
    }
    .multiselect-dropdown{
        box-shadow: 0 7px 12px rgb(159 159 159);
        bottom: -2px;
        right: 0;
        left: 1px
    }
    .multiselect-multiple-label, .multiselect-placeholder, .multiselect-single-label{
        padding-left: 15px
    }
    .multiselect.is-active {
        box-shadow: unset
    }
    .carousel__track > li.carousel__slide--visible{
        border: 1px solid #ddd;
        border-left: none !important
    }
    .carousel__track > li.carousel__slide--visible:first-child{
        border-left: 1px solid #ddd !important
    }
    .multiselect-option{
        color: #222222;
    }
    @media (min-width:576px) {
        .carousel__prev{
            left: -15px
        }
        .carousel__next{
            right: -15px
        }
    }
</style>